import React from 'react';

function PrivacyPolicyContent(props) {
    return (
        <div className="rn-blog-details pt--110 pb--70 bg_color--1">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner-wrapper">
                            <div className="inner privacy-content">

                                <h1>Izjava o dostopnosti spletišča Rudnik Sitarjevec Litija</h1>

                                <p className="mt-5">
                                    <strong>
                                    Ta izjava o dostopnosti se nanaša na spletišče rudniksitarjevec.si, ki je na
                                    podlagi koncesijske pogodbe o izvajanju izbirne gospodarske javne službe
                                    organiziranja in izvajanja spodbujanja razvoja turizma v Občini Litija, dan v
                                    upravljanje Razvojnemu centru Srca Slovenije, d.o.o., ki že od leta 2000 vodi,
                                    koordinira in povezuje razvojne projekte na območju Srca Slovenije v
                                    Republiki Sloveniji. Ta spletna stran je digitalna informacijska vstopna točka
                                    za vse obiskovalce spletišča rudniksitarjevec.si.</strong>
                                </p>

                                <p>Uporabo spletišča želimo približati najširšemu krogu uporabnikom, zato ga
                                    nenehno oblikujemo in prilagajamo tako, da je enostaven za uporabo in
                                    dostopen za uporabnike z različnimi oblikami invalidnosti in oviranosti:
                                    <ul>
                                        <li>uporabnikom z okvarami vida (slepim, slabovidnim, barvno slepim),</li>
                                        <li>uporabnikom z okvarami sluha (gluhim, naglušnim, gluho-slepim) ter</li>
                                        <li>uporabnikom z različnimi kognitivnimi okvarami in motnjami v
                                            razumevanju.</li>
                                    </ul>
                                </p>



                                <h3>
                                    Stopnja skladnosti
                                </h3>

                                <p>
                                    Spletišče rudniksitarjevec.si je usklajen z Zakonom o dostopnosti spletišč in
                                    mobilnih aplikacij javnega sektorja (ZDSMA). Spletne strani in vsebine so
                                    pripravljene v skladu z mednarodnimi smernicami za dostopnost spletnih
                                    vsebin in ustrezajo standardu WCAG (Web Content Accessibility Guidelines)
                                    2.0. Skladnost dostopnosti spletišča rudniksitarjevec.si s smernicami WCAG
                                    ustreza stopnji AA.
                                </p>

                                <p>
                                    <strong>Za slabovidne smo zagotovili:</strong>
                                    <ol>
                                        <li><strong>Zadostno kontrastnost:</strong></li>
                                        <ul>
                                            <li>Ustrezne barvne sheme omogočajo preglednost in berljivost spletišča:</li>
                                            <ul>
                                                <li>bel tekst na črni podlagi,</li>
                                                <li>črni tekst na beli podlagi,</li>
                                                <li>uporaba oranžnih odtenkov pri gumbih in pri prikazu odziva menijske
                                                    vrstice, ki imajo najvišjo stopnjo ocene kontrastnosti,</li>
                                                <li>Ugotavljamo, da v nekaj primerih podnaslovov, še lahko izboljšamo
                                                    kontrastnost. Ti podnaslovi se bodo še kontrastno optimizirali.</li>
                                            </ul>
                                        </ul>
                                        <li>
                                            <strong>Zadostno velikost pisave v naslovih in besedilih, skladno s HTML5
                                                standardi.</strong> Drobtinice na spletišču še niso dodane.
                                        </li>

                                        <li><strong>Bralniki</strong>, kot na primer »Narrator«, preberejo celotno vsebino, ki je
                                            objavljena na spletišču, ne glede na spletni iskalnik:</li>
                                        <ul>
                                            <li>lahko se zgodi, da določene slike ne bodo opisane z bralnikom, ker še ni zagotovljen opis za vse objavljene fotografije.</li>
                                        </ul>
                                    </ol>
                                </p>

                                <p>
                                    Opisi dogodkov, splošne informacije in druge vsebine so dopolnjene z
                                    grafičnimi elementi, za lažje branje in razumevanje vsebine, ki imajo ustrezno
                                    besedilno alternativo. Lahko se zgodi, da kateri od grafičnih elementov (npr.
                                    slike) tehnični pripomočki ne bodo zaznali, kot so bralniki spletnih strani in
                                    tipkovnice. Tudi te pomanjkljivosti bodo postopoma odpravljene.
                                </p>

                                <p>
                                    Pri pisanju besedil pazimo na razumljiva in enostavna besedila. V primeru, da
                                    jih uporabniki ne razumejo so vsi naši kontaktni podatki objavljeni na spletišču,
                                    tako da nas uporabniki vedno lahko kontaktirajo za pomoč.
                                </p>

                                <p>
                                    Ugotavljamo da spletišče rudniksitarjevec.si v <strong>94% zagotavlja dostopnost</strong>
                                    svojim uporabnikom.
                                </p>

                                <p>
                                    Spletišče rudniksitarjevec.si ima objavljen <strong>zemljevid celotnega spletnega
                                    mesta.</strong>
                                </p>

                                <p>
                                    Vse podstrani spletišča imajo svoja <strong>unikatna imena</strong>, ki se prikažejo na zavihku
                                    brskalnika.
                                </p>

                                <p>
                                    <strong>Meni Vsebine</strong> je ustrezno dostopen za uporabo in ne zaznavamo težav niti pri
                                    bralnikih zaslona.
                                </p>

                                <p>
                                    <strong>Iskalnik</strong> po spletišču rudniksitarjeves.si izvaja iskanja po vseh spletnih mestih, ki
                                    so del tega spletišča.
                                </p>

                                <p>
                                    Gnezdenje <strong>naslovnih oznak</strong> poglavij (Naslovi ali Headings) na spletišču v večini
                                    spletnih mest sledi priporočenemu zaporedju.
                                </p>

                                <p>
                                    <strong>Obrazec za rezervacijo</strong>, ki je objavljen na spletišču, je odziven na tehnične
                                    pripomočke.
                                </p>

                                <h3>
                                    Povratne in kontaktne informacije
                                </h3>

                                <p>
                                    Če nam želite sporočiti karkoli v zvezi s prilagoditvijo dostopnosti spletišča
                                    rudniksitarjevec.si za uporabnike z različnimi invalidnostmi in oviranostmi, nas
                                    lahko o tem obvestite na elektronski naslov <a href="mailto:info@visitlitija.si">
                                    info@visitlitija.si</a> ali po telefonu +386 51 312 739.
                                </p>

                                <p>
                                    Delovni čas Razvojnega centra Srca Slovenije je <strong>vsak delovnik od 8 do 16 ure.</strong>
                                </p>

                                <h3>Nadzor nad izvajanjem določb Zakona o dostopnosti spletišč in mobilnih
                                    aplikacij (ZDSMA)</h3>

                                <div><strong>Inšpektorat za informacijsko družbo</strong></div>
                                <div>Davčna ulica 1</div>
                                <div>1000 Ljubljana</div>
                                <div>e-naslov: gp.irsid@gov.si</div>
                                <div>Spletno mesto: https://www.gov.si/drzavni-organi/organi-v-sestavi/inspektorat-za-informacijsko-druzbo/ </div>



                                <div className="mt-5 font-weight-bold">
                                    Datum objave spletišča rudniksitarjevec.si: 02.05.2021
                                </div>

                                <h3 className="mt-5">Kazalo strani</h3>

                                <strong>/</strong>
                                <ul>
                                    <li><a href="https://rudniksitarjevec.si/">https://rudniksitarjevec.si/</a></li>
                                    <li><a href="https://rudniksitarjevec.si/razisci-rudnik-sitarjevec">https://rudniksitarjevec.si/razisci-rudnik-sitarjevec</a></li>
                                    <li><a href="https://rudniksitarjevec.si/cenik">https://rudniksitarjevec.si/cenik</a></li>
                                    <li><a href="https://rudniksitarjevec.si/kontakt-in-informacije">https://rudniksitarjevec.si/kontakt-in-informacije</a></li>
                                    <li><a href="https://rudniksitarjevec.si/politika-varstva-osebnih-podatkov">https://rudniksitarjevec.si/politika-varstva-osebnih-podatkov</a></li>
                                </ul>

                                <strong>en/</strong>
                                <ul>

                                    <li><a href="https://rudniksitarjevec.si/en">https://rudniksitarjevec.si/en</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/explore-the-mine">https://rudniksitarjevec.si/en/explore-the-mine</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/price-list">https://rudniksitarjevec.si/en/price-list</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/contact-and-information">https://rudniksitarjevec.si/en/contact-and-information</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/personal-data-protection-policy">https://rudniksitarjevec.si/en/personal-data-protection-policy</a></li>
                                </ul>

                                <strong>visit-the-mine/</strong>
                                <ul>
                                    <li><a href="https://rudniksitarjevec.si/en/visit-the-mine/the-main-pit">https://rudniksitarjevec.si/en/visit-the-mine/the-main-pit</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/visit-the-mine/the-export-pit">https://rudniksitarjevec.si/en/visit-the-mine/the-export-pit</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/visit-the-mine/animated-tours">https://rudniksitarjevec.si/en/visit-the-mine/animated-tours</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/visit-the-mine/culinary">https://rudniksitarjevec.si/en/visit-the-mine/culinary</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/visit-the-mine/events">https://rudniksitarjevec.si/en/visit-the-mine/events</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs">https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs</a></li>

                                </ul>

                                <strong>shop-and-souvenirs/</strong>
                                <ul>
                                    <li><a href="https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/spominki">https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/spominki</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/hematitni-nakit">https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/hematitni-nakit</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/knjiga-v-temnih-globinah-sitarjevca">https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/knjiga-v-temnih-globinah-sitarjevca</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/knjiga-stara-sodnija">https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/knjiga-stara-sodnija</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/knjiga-ko-litija-se-ni-bila-litija">https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/knjiga-ko-litija-se-ni-bila-litija</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/nahrbtnik-litko">https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/nahrbtnik-litko</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/svincnik-rudnik-sitarjevec">https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/svincnik-rudnik-sitarjevec</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/nogavice">https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/nogavice</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/razglednice">https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/razglednice</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/cokoladne-dobrote">https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/cokoladne-dobote</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/svinceni-ulitki">https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/svinceni-ulitki</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/keramicni-izdelki">https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/keramicni-izdelki</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/drobiznica-in-puscica">https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/drobiznica-in-puscica</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/barvice">https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/barvice</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/igraca-netopir">https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/igraca-netopir</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/obesek-netopir">https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/obesek-netopir</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/knjiga-fosili-slovenije">https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/knjiga-fosili-slovenije</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/loncki">https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/loncki</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/majice">https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/majice</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/kemicni-svincnik">https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/kemicni-svincnik</a></li>
                                    <li><a href="https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/unikatna-voscilnica">https://rudniksitarjevec.si/en/visit-the-mine/shop-and-souvenirs/unikatna-voscilnica</a></li>
                                </ul>

                                <strong>the-export-pit/</strong>
                                <ul>
                                    <li><a href="https://rudniksitarjevec.si/en/visit-the-mine/the-export-pit/">https://rudniksitarjevec.si/en/visit-the-mine/the-export-pit/</a></li>
                                </ul>
                                <strong>obisci-rudnik/</strong>
                                <ul>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/glavni-rov">https://rudniksitarjevec.si/obisci-rudnik/glavni-rov</a></li>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/izvozni-rov">https://rudniksitarjevec.si/obisci-rudnik/izvozni-rov</a></li>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/sitarjevski-hodi">https://rudniksitarjevec.si/obisci-rudnik/sitarjevski-hodi</a></li>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/animirana-vodenja">https://rudniksitarjevec.si/obisci-rudnik/animirana-vodenja</a></li>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/kulinarika">https://rudniksitarjevec.si/obisci-rudnik/kulinarika</a></li>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/tradicionalni-dogodki">https://rudniksitarjevec.si/obisci-rudnik/tradicionalni-dogodki</a></li>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki">https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki</a></li>
                                </ul>

                                <strong>trgovina-in-spominki/</strong>
                                <ul>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/spominki">https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/spominki</a></li>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/hematitni-nakit">https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/hematitni-nakit</a></li>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/knjiga-v-temnih-globinah-sitarjevca">https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/knjiga-v-temnih-globinah-sitarjevca</a></li>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/knjiga-stara-sodnija">https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/knjiga-stara-sodnija</a></li>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/knjiga-ko-litija-se-ni-bila-litija">https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/knjiga-ko-litija-se-ni-bila-litija</a></li>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/nahrbtnik-litko">https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/nahrbtnik-litko</a></li>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/svincnik-rudnik-sitarjevec">https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/svincnik-rudnik-sitarjevec</a></li>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/nogavice">https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/nogavice</a></li>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/razglednice">https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/razglednice</a></li>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/magneti">https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/magneti</a></li>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/cokoladne-dobrote">https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/cokoladne-dobrote</a></li>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/svinceni-ulitki">https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/svinceni-ulitki</a></li>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/keramicni-izdelki">https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/keramicni-izdelki</a></li>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/drobiznica-in-puscica">https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/drobiznica-in-puscica</a></li>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-sp-spominki/barvice">https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/barvice</a></li>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/igraca-netopir">https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/igraca-netopir</a></li>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/obesek-netopir">https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/obesek-netopir</a></li>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/knjiga-fosili-slovenije">https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/knjiga-fosili-slovenije</a></li>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/loncki">https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/loncki</a></li>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/majice">https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/majice</a></li>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/kemicni-svincnik">https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/kemicni-svincnik</a></li>
                                    <li><a href="https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/unikatna-voscilnica">https://rudniksitarjevec.si/obisci-rudnik/trgovina-in-spominki/unikatna-voscilnica</a></li>
                                </ul>

                                <h3>Datoteke</h3>
                                <ul>
                                    <li><a href="https://rudniksitarjevec.si/static/mining-heritage-in-slovenia-c626d8ece4523b471d5684e618f14da8.pdf">https://rudniksitarjevec.si/static/mining-heritage-in-slovenia-c626d8ece4523b471d5684e618f14da8.pdf</a></li>
                                    <li><a href="https://rudniksitarjevec.si/static/mineral-fest-2018-61d6f1593482f8bc790f6dd361c116fd.pdf">https://rudniksitarjevec.si/static/mineral-fest-2018-61d6f1593482f8bc790f6dd361c116fd.pdf</a></li>
                                    <li><a href="https://rudniksitarjevec.si/static/simpozij-rudnik-sitarjevec-ff71611f8c23970cb156d4824d42f5a6.pdf">https://rudniksitarjevec.si/static/simpozij-rudnik-sitarjevec-ff71611f8c23970cb156d4824d42f5a6.pdf</a></li>
                                </ul>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicyContent;
