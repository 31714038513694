import React from 'react';
import SEO from '../components/seo/Seo';
import Header from "../components/headers/Header";
import Footer from "../components/layouts/Footer";
import AccessibilityHeader from "../components/accessibility/AccessibilityHeader";
import AccessibilityContent from "../components/accessibility/AccessibilityContent";

function IzjavaODostopnosti(props) {
    return (
        <div>
            <SEO title="Izjava o dostopnosti spletišča Rudnik Sitarjevec Litija" description="Ta izjava o dostopnosti se nanaša na spletišče rudniksitarjevec.si, ki je na podlagi koncesijske pogodbe o izvajanju izbirne gospodarske javne službe organiziranja in izvajanja spodbujanja razvoja turizma v Občini Litija, dan v upravljanje Razvojnemu centru Srca Slovenije, d.o.o., ki že od leta 2000 vodi,koordinira in povezuje razvojne projekte na območju Srca Slovenije v Republiki Sloveniji. Ta spletna stran je digitalna informacijska vstopna točka za vse obiskovalce spletišča rudniksitarjevec.si."/>
            <Header/>
            <AccessibilityHeader/>
            <AccessibilityContent/>
            <Footer />
        </div>
    );
}

export default IzjavaODostopnosti;